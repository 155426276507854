<template>
  <div class="entity-details-review">
    <div class="local-loader-container" v-if="loading">
      <div class="inava-loader"></div>
    </div>

    <div class="menu-stepper-container">
      <MenuStepper
        :value="activeStepIndex"
        @change="handleCollapseChange"
        :items="stepList"
      ></MenuStepper>
    </div>
    <!-- main content -->
    <section class="form-review-container">
      <b-collapse
        class="card"
        animation="slide"
        v-for="(item, index) in items"
        :key="index"
        :open="getOpenState(index)"
        @open="handleCollapseChange(index)"
        @close="closeCollapseChange(index)"
        :aria-id="'contentIdForA11y5-' + index"
      >
        <template #trigger="props">
          <div
            :class="[{ 'skeleton-loading': item.isLoading }, 'card-header']"
            role="button"
            :aria-controls="'contentIdForA11y5-' + index"
            :aria-expanded="props.open"
          >
            <p class="card-header-title">
              {{ item.title }}
            </p>
            <a class="card-header-icon">
              <Loader
                v-if="item.isLoading"
                size="small"
                type="converging-spinner"
                :theme-color="'info'"
              />
              <b-icon
                v-else
                :icon="
                  props.open
                    ? 'arrow-down-drop-circle-outline'
                    : 'arrow-up-drop-circle-outline'
                "
              >
              </b-icon>
            </a>
          </div>
        </template>

        <div class="card-content">
          <div class="content">
            <template v-if="item.content === 'basicDetails'">
              <div class="accordian-data">
                <div class="accordian-data-row">
                  <div class="row-item">
                    <p class="input-label">{{ SGA_ID }}</p>
                    <p class="input-field">{{ sgaId }}</p>
                  </div>
                  <div class="row-item">
                    <p class="input-label">{{ ALIAS }}</p>
                    <p class="input-field" v-if="newBasicDetails.alias">
                      {{ newBasicDetails.alias }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(ALIAS) }}
                    </p>
                  </div>
                  <div class="row-item">
                    <p class="input-label">
                      {{ LEGAL_ENTITY_NAME }}<span class="mandatory">*</span>
                    </p>
                    <p
                      class="input-field"
                      v-if="newBasicDetails.legalEntityName"
                    >
                      {{ newBasicDetails.legalEntityName }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(LEGAL_ENTITY_NAME) }}
                    </p>
                  </div>
                </div>
                <div class="accordian-data-row">
                  <div class="row-item">
                    <p class="input-label">
                      {{ PREVIOUS_ENTITY_NAME }}
                    </p>
                    <p
                      class="input-field"
                      v-if="newBasicDetails.previousEntityName"
                    >
                      {{ newBasicDetails.previousEntityName }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(PREVIOUS_ENTITY_NAME) }}
                    </p>
                  </div>
                  <div class="row-item">
                    <p class="input-label">{{ LEGAL_STRUCTURE }}</p>
                    <p
                      class="input-field"
                      v-if="newBasicDetails.legalStructure"
                    >
                      {{ newBasicDetails.legalStructure }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(LEGAL_STRUCTURE) }}
                    </p>
                  </div>
                  <div class="row-item">
                    <p class="input-label">{{ BUSINESS_STATUS }}</p>
                    <p
                      class="input-field"
                      v-if="newBasicDetails.businessStatus"
                    >
                      {{ newBasicDetails.businessStatus }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(BUSINESS_STATUS) }}
                    </p>
                  </div>
                </div>
                <div class="accordian-data-row">
                  <div class="row-item">
                    <p class="input-label">
                      Reason
                      <span
                        v-if="newBasicDetails.businessStatus === 'Inactive'"
                        class="mandatory"
                        >*</span
                      >
                    </p>
                    <p
                      class="input-field"
                      v-if="newBasicDetails.businessStatusInactiveReason"
                    >
                      {{ newBasicDetails.businessStatusInactiveReason }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(BUSINESS_STATUS_INACTIVATION_REASON) }}
                    </p>
                  </div>
                  <div class="row-item">
                    <p class="input-label">
                      {{ ENTITY_TYPE }}<span class="mandatory">*</span>
                    </p>
                    <p
                      class="input-field"
                      v-if="newBasicDetails.entityTypeValue"
                    >
                      {{ newBasicDetails.entityTypeValue }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(ENTITY_TYPE) }}
                    </p>
                  </div>
                  <div class="row-item">
                    <p class="input-label">
                      {{ ENTITY_SUB_TYPE }}<span class="mandatory">*</span>
                    </p>
                    <p
                      class="input-field"
                      v-if="newBasicDetails.entitySubTypeValue"
                    >
                      {{ newBasicDetails.entitySubTypeValue }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(ENTITY_SUB_TYPE) }}
                    </p>
                  </div>
                </div>
                <div class="accordian-data-row">
                  <div class="row-item">
                    <p class="input-label">
                      {{ US_GSE_FLAG }}<span class="mandatory">*</span>
                    </p>
                    <p class="input-field" v-if="newBasicDetails.usGseFlag">
                      {{ newBasicDetails.usGseFlag }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(US_GSE_FLAG) }}
                    </p>
                  </div>
                  <div class="row-item">
                    <p class="input-label">{{ BRANCH_INDICATOR }}</p>
                    <p
                      class="input-field"
                      v-if="newBasicDetails.branchIndicator"
                    >
                      {{ newBasicDetails.branchIndicator }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(BRANCH_INDICATOR) }}
                    </p>
                  </div>
                  <div class="row-item">
                    <!-- empty col -->
                  </div>
                </div>
              </div>
            </template>
            <template v-else-if="item.content === 'addressDetails'">
              <div class="accordian-data">
                <p class="accodian-data-section-title">
                  Physical/Business Address
                </p>
                <div class="accordian-data-row">
                  <div class="row-item">
                    <p class="input-label">{{ ADDRESS_LINE_1 }}</p>
                    <p class="input-field" v-if="newPhysicalAddress.addrLine1">
                      {{ newPhysicalAddress.addrLine1 }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(ADDRESS_LINE_1) }}
                    </p>
                  </div>
                  <div class="row-item row-item-empty">
                    <!-- empty col -->
                  </div>
                  <div class="row-item row-item-empty">
                    <!-- empty col -->
                  </div>
                </div>

                <div class="accordian-data-row">
                  <div class="row-item">
                    <p class="input-label">{{ FULL_ADDRESS }}</p>
                    <p
                      class="input-field"
                      v-if="newPhysicalAddress.fullAddress"
                    >
                      {{ newPhysicalAddress.fullAddress }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(FULL_ADDRESS) }}
                    </p>
                  </div>
                  <div class="row-item row-item-empty">
                    <!-- empty col -->
                  </div>
                  <div class="row-item row-item-empty">
                    <!-- empty col -->
                  </div>
                </div>

                <div class="accordian-data-row">
                  <div class="row-item">
                    <p class="input-label">{{ COMPANY_NAME }}</p>

                    <p
                      class="input-field"
                      v-if="newPhysicalAddress.companyName"
                    >
                      {{ newPhysicalAddress.companyName }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(COMPANY_NAME) }}
                    </p>
                  </div>
                  <div class="row-item row-item-empty">
                    <!-- empty col -->
                  </div>
                  <div class="row-item row-item-empty">
                    <!-- empty col -->
                  </div>
                </div>
                <div class="accordian-data-row">
                  <div class="row-item">
                    <p class="input-label">{{ DEPARTMENT }}</p>
                    <p class="input-field" v-if="newPhysicalAddress.department">
                      {{ newPhysicalAddress.department }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(DEPARTMENT) }}
                    </p>
                  </div>
                  <div class="row-item">
                    <p class="input-label">{{ SUB_DEPARTMENT }}</p>
                    <p
                      class="input-field"
                      v-if="newPhysicalAddress.subDepartment"
                    >
                      {{ newPhysicalAddress.subDepartment }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(SUB_DEPARTMENT) }}
                    </p>
                  </div>
                  <div class="row-item">
                    <p class="input-label">
                      {{ STREET_NAME }}
                    </p>
                    <p class="input-field" v-if="newPhysicalAddress.streetName">
                      {{ newPhysicalAddress.streetName }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(STREET_NAME) }}
                    </p>
                  </div>
                </div>
                <div class="accordian-data-row">
                  <div class="row-item">
                    <p class="input-label">
                      {{ BUILDING_NO }}
                    </p>
                    <p
                      class="input-field"
                      v-if="newPhysicalAddress.buildingNumber"
                    >
                      {{ newPhysicalAddress.buildingNumber }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(BUILDING_NO) }}
                    </p>
                  </div>
                  <div class="row-item">
                    <p class="input-label">{{ BUILDING_NAME }}</p>
                    <p
                      class="input-field"
                      v-if="newPhysicalAddress.buildingName"
                    >
                      {{ newPhysicalAddress.buildingName }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(BUILDING_NAME) }}
                    </p>
                  </div>
                  <div class="row-item">
                    <p class="input-label">{{ FLOOR }}</p>
                    <p class="input-field" v-if="newPhysicalAddress.floor">
                      {{ newPhysicalAddress.floor }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(FLOOR) }}
                    </p>
                  </div>
                </div>
                <div class="accordian-data-row">
                  <div class="row-item">
                    <p class="input-label">
                      {{ POSTBOX }}
                    </p>
                    <p class="input-field" v-if="newPhysicalAddress.postBox">
                      {{ newPhysicalAddress.postBox }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(POSTBOX) }}
                    </p>
                  </div>
                  <div class="row-item">
                    <p class="input-label">
                      {{ ROOM }}
                    </p>
                    <p class="input-field" v-if="newPhysicalAddress.room">
                      {{ newPhysicalAddress.room }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(ROOM) }}
                    </p>
                  </div>
                  <div class="row-item">
                    <p class="input-label">
                      {{ POSTCODE }}<span class="mandatory">*</span>
                    </p>
                    <p class="input-field" v-if="newPhysicalAddress.postCode">
                      {{ newPhysicalAddress.postCode }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(POSTCODE) }}
                    </p>
                  </div>
                </div>
                <div class="accordian-data-row">
                  <div class="row-item">
                    <p class="input-label">
                      {{ COUNTRY }} <span class="mandatory">*</span>
                    </p>
                    <p class="input-field" v-if="newPhysicalAddress.country">
                      {{ newPhysicalAddress.country }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(COUNTRY) }}
                    </p>
                  </div>
                  <div class="row-item">
                    <p class="input-label">
                      {{ TOWN_NAME }}
                    </p>
                    <p class="input-field" v-if="newPhysicalAddress.town">
                      {{ newPhysicalAddress.town }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(TOWN_NAME) }}
                    </p>
                  </div>
                  <div class="row-item">
                    <p class="input-label">
                      {{ TOWN_LOC_NAME }}
                    </p>
                    <p
                      class="input-field"
                      v-if="newPhysicalAddress.townLocation"
                    >
                      {{ newPhysicalAddress.townLocation }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(TOWN_LOC_NAME) }}
                    </p>
                  </div>
                </div>
                <div class="accordian-data-row">
                  <div class="row-item">
                    <p class="input-label">
                      {{ DISTRICT_NAME }}
                    </p>
                    <p
                      class="input-field"
                      v-if="newPhysicalAddress.districtName"
                    >
                      {{ newPhysicalAddress.districtName }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(DISTRICT_NAME) }}
                    </p>
                  </div>
                  <div class="row-item">
                    <p class="input-label">
                      {{ COUNTRY_SUB_DIV }} <span class="mandatory">*</span>
                    </p>
                    <p
                      class="input-field"
                      v-if="newPhysicalAddress.subDivision"
                    >
                      {{ newPhysicalAddress.subDivision }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(COUNTRY_SUB_DIV) }}
                    </p>
                  </div>

                  <div class="row-item">
                    <p class="input-label">{{ NUTS3_CODE }}</p>
                    <p class="input-field" v-if="newPhysicalAddress.nuts3">
                      {{ newPhysicalAddress.nuts3 }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(NUTS3_CODE) }}
                    </p>
                  </div>
                </div>

                <p class="accodian-data-section-title">Registered Address</p>
                <div class="accordian-data-row">
                  <div class="row-item">
                    <p class="input-label">{{ ADDRESS_LINE_1 }}</p>
                    <p
                      class="input-field"
                      v-if="newRegisteredAddress.addrLine1"
                    >
                      {{ newRegisteredAddress.addrLine1 }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(ADDRESS_LINE_1) }}
                    </p>
                  </div>
                  <div class="row-item row-item-empty">
                    <!-- empty col -->
                  </div>
                  <div class="row-item row-item-empty">
                    <!-- empty col -->
                  </div>
                </div>

                <div class="accordian-data-row">
                  <div class="row-item">
                    <p class="input-label">{{ FULL_ADDRESS }}</p>
                    <p
                      class="input-field"
                      v-if="newRegisteredAddress.fullAddress"
                    >
                      {{ newRegisteredAddress.fullAddress }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(FULL_ADDRESS) }}
                    </p>
                  </div>
                  <div class="row-item row-item-empty">
                    <!-- empty col -->
                  </div>
                  <div class="row-item row-item-empty">
                    <!-- empty col -->
                  </div>
                </div>

                <div class="accordian-data-row">
                  <div class="row-item">
                    <p class="input-label">{{ COMPANY_NAME }}</p>
                    <p
                      class="input-field"
                      v-if="newRegisteredAddress.companyName"
                    >
                      {{ newRegisteredAddress.companyName }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(COMPANY_NAME) }}
                    </p>
                  </div>
                  <div class="row-item row-item-empty">
                    <!-- empty col -->
                  </div>
                  <div class="row-item row-item-empty">
                    <!-- empty col -->
                  </div>
                </div>

                <div class="accordian-data-row">
                  <div class="row-item">
                    <p class="input-label">{{ DEPARTMENT }}</p>
                    <p
                      class="input-field"
                      v-if="newRegisteredAddress.department"
                    >
                      {{ newRegisteredAddress.department }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(DEPARTMENT) }}
                    </p>
                  </div>
                  <div class="row-item">
                    <p class="input-label">{{ SUB_DEPARTMENT }}</p>
                    <p
                      class="input-field"
                      v-if="newRegisteredAddress.subDepartment"
                    >
                      {{ newRegisteredAddress.subDepartment }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(SUB_DEPARTMENT) }}
                    </p>
                  </div>
                  <div class="row-item">
                    <p class="input-label">
                      {{ STREET_NAME }}
                    </p>
                    <p
                      class="input-field"
                      v-if="newRegisteredAddress.streetName"
                    >
                      {{ newRegisteredAddress.streetName }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(STREET_NAME) }}
                    </p>
                  </div>
                </div>
                <div class="accordian-data-row">
                  <div class="row-item">
                    <p class="input-label">
                      {{ BUILDING_NO }}
                    </p>
                    <p
                      class="input-field"
                      v-if="newRegisteredAddress.buildingNumber"
                    >
                      {{ newRegisteredAddress.buildingNumber }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(BUILDING_NO) }}
                    </p>
                  </div>
                  <div class="row-item">
                    <p class="input-label">{{ BUILDING_NAME }}</p>
                    <p
                      class="input-field"
                      v-if="newRegisteredAddress.buildingName"
                    >
                      {{ newRegisteredAddress.buildingName }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(BUILDING_NAME) }}
                    </p>
                  </div>

                  <div class="row-item">
                    <p class="input-label">{{ FLOOR }}</p>
                    <p class="input-field" v-if="newRegisteredAddress.floor">
                      {{ newRegisteredAddress.floor }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(FLOOR) }}
                    </p>
                  </div>
                </div>
                <div class="accordian-data-row">
                  <div class="row-item">
                    <p class="input-label">
                      {{ POSTBOX }}
                    </p>
                    <p class="input-field" v-if="newRegisteredAddress.postBox">
                      {{ newRegisteredAddress.postBox }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(POSTBOX) }}
                    </p>
                  </div>
                  <div class="row-item">
                    <p class="input-label">
                      {{ ROOM }}
                    </p>
                    <p class="input-field" v-if="newRegisteredAddress.room">
                      {{ newRegisteredAddress.room }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(ROOM) }}
                    </p>
                  </div>
                  <div class="row-item">
                    <p class="input-label">
                      {{ POSTCODE }}<span class="mandatory">*</span>
                    </p>
                    <p class="input-field" v-if="newRegisteredAddress.postCode">
                      {{ newRegisteredAddress.postCode }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(POSTCODE) }}
                    </p>
                  </div>
                </div>
                <div class="accordian-data-row">
                  <div class="row-item">
                    <p class="input-label">
                      {{ COUNTRY }} <span class="mandatory">*</span>
                    </p>
                    <p class="input-field" v-if="newRegisteredAddress.country">
                      {{ newRegisteredAddress.country }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(COUNTRY) }}
                    </p>
                  </div>
                  <div class="row-item">
                    <p class="input-label">
                      {{ TOWN_NAME }}
                    </p>
                    <p class="input-field" v-if="newRegisteredAddress.town">
                      {{ newRegisteredAddress.town }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(TOWN_NAME) }}
                    </p>
                  </div>
                  <div class="row-item">
                    <p class="input-label">
                      {{ TOWN_LOC_NAME }}
                    </p>
                    <p
                      class="input-field"
                      v-if="newRegisteredAddress.townLocation"
                    >
                      {{ newRegisteredAddress.townLocation }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(TOWN_LOC_NAME) }}
                    </p>
                  </div>
                </div>
                <div class="accordian-data-row">
                  <div class="row-item">
                    <p class="input-label">
                      {{ DISTRICT_NAME }}
                    </p>
                    <p
                      class="input-field"
                      v-if="newRegisteredAddress.districtName"
                    >
                      {{ newRegisteredAddress.districtName }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(DISTRICT_NAME) }}
                    </p>
                  </div>
                  <div class="row-item">
                    <p class="input-label">
                      {{ COUNTRY_SUB_DIV }} <span class="mandatory">*</span>
                    </p>
                    <p
                      class="input-field"
                      v-if="newRegisteredAddress.subDivision"
                    >
                      {{ newRegisteredAddress.subDivision }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(COUNTRY_SUB_DIV) }}
                    </p>
                  </div>

                  <div class="row-item">
                    <p class="input-label">{{ NUTS3_CODE }}</p>
                    <p class="input-field" v-if="newRegisteredAddress.nuts3">
                      {{ newRegisteredAddress.nuts3 }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(NUTS3_CODE) }}
                    </p>
                  </div>
                </div>
              </div>
            </template>
            <template v-else-if="item.content === 'industryDetails'">
              <div class="accordian-data">
                <!-- wip -->
                <div class="accordian-data-row">
                  <div class="row-item">
                    <p class="input-label">
                      {{ NATURE_OF_BUSINESS }} <span class="mandatory">*</span>
                    </p>
                    <p
                      class="input-field"
                      v-if="newIndustryDetails.businessNature"
                    >
                      {{ newIndustryDetails.businessNature }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(NATURE_OF_BUSINESS) }}
                    </p>
                  </div>
                  <div class="row-item row-item-empty">
                    <!-- empty col -->
                  </div>
                  <div class="row-item row-item-empty">
                    <!-- empty col -->
                  </div>
                </div>
                <div class="accordian-data-row">
                  <div class="row-item">
                    <p class="input-label">
                      {{ NAICS }} <span class="mandatory">*</span>
                    </p>
                    <p class="input-field" v-if="newIndustryDetails.naics">
                      {{ newIndustryDetails.naics }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(NAICS) }}
                    </p>
                  </div>
                  <div class="row-item">
                    <p class="input-label">{{ SECONDARY_NAICS }}</p>
                    <p
                      class="input-field"
                      v-if="newIndustryDetails.secondaryNaics"
                    >
                      {{ newIndustryDetails.secondaryNaics }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(SECONDARY_NAICS) }}
                    </p>
                  </div>
                  <div class="row-item">
                    <p class="input-label">
                      {{ SIC }}<span class="mandatory">*</span>
                    </p>
                    <p class="input-field" v-if="newIndustryDetails.sic">
                      {{ newIndustryDetails.sic }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(SIC) }}
                    </p>
                  </div>
                </div>
                <div class="accordian-data-row">
                  <div class="row-item">
                    <p class="input-label">
                      {{ NACE }}<span class="mandatory">*</span>
                    </p>
                    <p class="input-field" v-if="newIndustryDetails.nace">
                      {{ newIndustryDetails.nace }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(NACE) }}
                    </p>
                  </div>
                  <div class="row-item">
                    <p class="input-label">
                      {{ SECONDARY_NACE }}
                      <span
                        class="mandatory"
                        v-if="newIndustryDetails.secondaryNaics"
                        >*</span
                      >
                    </p>
                    <p
                      class="input-field"
                      v-if="newIndustryDetails.secondaryNace"
                    >
                      {{ newIndustryDetails.secondaryNace }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(SECONDARY_NACE) }}
                    </p>
                  </div>
                  <div class="row-item">
                    <p class="input-label">{{ GERMAN_EXTENDED_NACE }}</p>
                    <p
                      class="input-field"
                      v-if="newIndustryDetails.germanExtNace"
                    >
                      {{ newIndustryDetails.germanExtNace }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(GERMAN_EXTENDED_NACE) }}
                    </p>
                  </div>
                </div>
              </div>
            </template>
            <template v-if="item.content === 'identifiersDetails'">
              <div class="accordian-data">
                <div class="accordian-data-row">
                  <div class="row-item">
                    <p class="input-label">
                      {{ CRN }}
                    </p>
                    <p class="input-field" v-if="newIdentifiersDetails.crn">
                      {{ newIdentifiersDetails.crn }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(CRN) }}
                    </p>
                  </div>
                  <div class="row-item">
                    <p class="input-label">
                      {{ INAI }}
                      <span v-if="newIdentifiersDetails.crn" class="mandatory"
                        >*</span
                      >
                    </p>
                    <p class="input-field" v-if="newIdentifiersDetails.inai">
                      {{ newIdentifiersDetails.inai }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(INAI) }}
                    </p>
                  </div>
                </div>
                <template
                  v-for="(pair, index) in newIdentifiersDetails.taxIdPairs"
                >
                  <div class="accordian-data-row">
                    <div class="row-item">
                      <p class="input-label">
                        {{ TAX_ID_TYPE }}
                      </p>
                      <p class="input-field" v-if="pair">
                        {{ pair.typeCode }} - {{ pair.typeLabel }}
                      </p>
                      <p class="input-field-empty" v-else>
                        {{ getPlaceholder(TAX_ID_TYPE) }}
                      </p>
                    </div>
                    <div class="row-item">
                      <p class="input-label">{{ TAX_ID }}</p>
                      <p class="input-field" v-if="pair">
                        {{ pair.value }}
                      </p>
                      <p class="input-field-empty" v-else>
                        {{ getPlaceholder(TAX_ID) }}
                      </p>
                    </div>
                  </div>
                </template>
                <div class="accordian-data-row">
                  <div class="row-item">
                    <p class="input-label">{{ LEI }}</p>
                    <p class="input-field" v-if="newIdentifiersDetails.lei">
                      {{ newIdentifiersDetails.lei }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(LEI) }}
                    </p>
                  </div>
                  <div class="row-item">
                    <p class="input-label">{{ CIK }}</p>
                    <p class="input-field" v-if="newIdentifiersDetails.cik">
                      {{ newIdentifiersDetails.cik }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(CIK) }}
                    </p>
                  </div>
                </div>
                <div class="accordian-data-row">
                  <div class="row-item">
                    <p class="input-label">
                      {{ RSSD }}
                    </p>
                    <p class="input-field" v-if="newIdentifiersDetails.rssd">
                      {{ newIdentifiersDetails.rssd }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(RSSD) }}
                    </p>
                  </div>
                  <div class="row-item">
                    <p class="input-label">
                      {{ CRD }}
                    </p>
                    <p class="input-field" v-if="newIdentifiersDetails.crd">
                      {{ newIdentifiersDetails.crd }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(CRD) }}
                    </p>
                  </div>
                  <div class="row-item"></div>
                </div>
              </div>
            </template>
            <template v-if="item.content === 'anaCreditDetails'">
              <div class="accordian-data">
                <div v-if="!isAnaCreditApplicable" class="non-anacredit-msg">
                  This form is not available due to non applicability of
                  AnaCredit details.
                </div>
                <div v-if="isAnaCreditApplicable">
                  <template
                    v-for="(pair, index) in newAnaCreditDetails.identifierPairs"
                  >
                    <div class="accordian-data-row">
                      <div class="row-item">
                        <p class="input-label">
                          {{ NATIONAL_IDENTIFIERS_TYPE }}
                          <span
                            v-if="highestIdentifierRank > 0"
                            class="mandatory"
                            >*</span
                          >
                        </p>
                        <p class="input-field" v-if="pair">
                          {{
                            pair.typeRank !== ANA_C_IDENTIFIERS_NA.RANK &&
                            pair.typeRank !== ANA_C_IDENTIFIERS_NOT_APP.RANK
                              ? pair.typeRank + ' - '
                              : ''
                          }}{{ pair.typeCode }} - {{ pair.typeLabel }}
                        </p>
                        <p class="input-field-empty" v-else>
                          {{ getPlaceholder(NATIONAL_IDENTIFIERS_TYPE) }}
                        </p>
                      </div>
                      <div class="row-item">
                        <p class="input-label">
                          {{ NATIONAL_IDENTIFIERS }}
                          <span
                            v-if="highestIdentifierRank > 0"
                            class="mandatory"
                            >*</span
                          >
                        </p>
                        <p class="input-field" v-if="pair">
                          {{ pair.value }}
                        </p>
                        <p class="input-field-empty" v-else>
                          {{ getPlaceholder(NATIONAL_IDENTIFIERS) }}
                        </p>
                      </div>
                    </div>
                  </template>
                  <div class="accordian-data-row">
                    <div class="row-item">
                      <p class="input-label">
                        {{ INST_SECTOR }}
                        <span v-if="isAnaCreditApplicable" class="mandatory"
                          >*</span
                        >
                      </p>
                      <p
                        class="input-field"
                        v-if="newAnaCreditDetails.institutionalSector"
                      >
                        {{ newAnaCreditDetails.institutionalSector }}
                      </p>
                      <p class="input-field-empty" v-else>
                        {{ getPlaceholder(INST_SECTOR) }}
                      </p>
                    </div>
                    <div class="row-item">
                      <p class="input-label">
                        {{ LEGAL_FORM }}
                        <span v-if="isAnaCreditApplicable" class="mandatory"
                          >*</span
                        >
                      </p>
                      <p
                        class="input-field"
                        v-if="newAnaCreditDetails.legalForm"
                      >
                        {{ newAnaCreditDetails.legalForm }}
                      </p>
                      <p class="input-field-empty" v-else>
                        {{ getPlaceholder(LEGAL_FORM) }}
                      </p>
                    </div>
                  </div>

                  <hr />

                  <div class="accordian-data-row">
                    <div class="row-item">
                      <p class="ana-credit-subtitle">Legal Proceedings</p>
                    </div>
                    <div class="row-item">
                      <p class="input-label">
                        {{ LEGAL_PROCEED_DATE }}
                        <span
                          v-if="
                            isAnaCreditApplicable &&
                            newAnaCreditDetails.legalProceedStatus
                              ?.split('-')[0]
                              .trim() !== NO_LEGAL_ACTIONS_CODE
                          "
                          class="mandatory"
                        >
                          *
                        </span>
                      </p>
                      <p
                        class="input-field"
                        v-if="newAnaCreditDetails.legalProceedDate"
                      >
                        {{ newAnaCreditDetails.legalProceedDate }}
                      </p>
                      <p class="input-field-empty" v-else>
                        {{ getPlaceholder(LEGAL_PROCEED_DATE) }}
                      </p>
                    </div>
                    <div class="row-item">
                      <p class="input-label">
                        {{ LEGAL_PROCEED_STATUS }}
                        <span v-if="isAnaCreditApplicable" class="mandatory"
                          >*</span
                        >
                      </p>
                      <p
                        class="input-field"
                        v-if="newAnaCreditDetails.legalProceedStatus"
                      >
                        {{ newAnaCreditDetails.legalProceedStatus }}
                      </p>
                      <p class="input-field-empty" v-else>
                        {{ getPlaceholder(LEGAL_PROCEED_STATUS) }}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div class="accordian-data-row">
                    <div class="row-item">
                      <p class="ana-credit-subtitle">Enterprise Details</p>
                    </div>
                    <div class="row-item">
                      <p class="input-label">
                        {{ ENTERPRISE_SIZE_DATE }}
                        <span
                          v-if="
                            isAnaCreditApplicable &&
                            availabeEnterpriseSize.includes(
                              newAnaCreditDetails.enterpriseSize
                            )
                          "
                          class="mandatory"
                          >*</span
                        >
                      </p>

                      <p
                        class="input-field"
                        v-if="newAnaCreditDetails.enterpriseSizeDate"
                      >
                        {{ newAnaCreditDetails.enterpriseSizeDate }}
                      </p>
                      <p class="input-field-empty" v-else>
                        {{ getPlaceholder(ENTERPRISE_SIZE_DATE) }}
                      </p>
                    </div>

                    <div class="row-item">
                      <p class="input-label">
                        {{ NO_OF_EMPL }}
                      </p>
                      <p
                        class="input-field"
                        v-if="newAnaCreditDetails.noOfEmployees"
                      >
                        {{ newAnaCreditDetails.noOfEmployees }}
                      </p>
                      <p class="input-field-empty" v-else>
                        {{ getPlaceholder(NO_OF_EMPL) }}
                      </p>
                    </div>
                  </div>

                  <div class="accordian-data-row">
                    <div class="row-item">
                      <p class="input-label">{{ BALANCE_SHEET }}</p>
                      <p
                        class="input-field"
                        v-if="newAnaCreditDetails.balanceSheetTotal"
                      >
                        {{ newAnaCreditDetails.balanceSheetTotal }}
                      </p>
                      <p class="input-field-empty" v-else>
                        {{ getPlaceholder(BALANCE_SHEET) }}
                      </p>
                    </div>
                    <div class="row-item">
                      <p class="input-label">
                        {{ ANNUAL_TURNOVER }}
                      </p>
                      <p
                        class="input-field"
                        v-if="newAnaCreditDetails.annualTurnover"
                      >
                        {{ newAnaCreditDetails.annualTurnover }}
                      </p>
                      <p class="input-field-empty" v-else>
                        {{ getPlaceholder(ANNUAL_TURNOVER) }}
                      </p>
                    </div>
                    <div class="row-item">
                      <p class="input-label">
                        {{ ENTERPRISE_SIZE }}
                      </p>
                      <p
                        class="input-field"
                        v-if="newAnaCreditDetails.enterpriseSize"
                      >
                        {{ newAnaCreditDetails.enterpriseSize }}
                      </p>
                      <p class="input-field-empty" v-else>
                        {{ getPlaceholder(ENTERPRISE_SIZE) }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-if="item.content === '2052ADetails'">
              <div class="accordian-data">
                <div class="accordian-data-row">
                  <div class="row-item">
                    <p class="input-label">
                      {{ GSIB }}
                    </p>
                    <p class="input-field" v-if="newADetails.gSib">
                      {{ newADetails.gSib }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(GSIB) }}
                    </p>
                  </div>
                  <div class="row-item">
                    <p class="input-label">
                      {{ GSIB_NAME }}
                      <span v-if="newADetails.gSib" class="mandatory">*</span>
                    </p>
                    <p class="input-field" v-if="newADetails.gSibName">
                      {{ newADetails.gSibName }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(GSIB_NAME) }}
                    </p>
                  </div>
                  <div class="row-item">
                    <p class="input-label">
                      {{ SUPRANATIONAL_ENTITY }}
                    </p>
                    <p class="input-field" v-if="newADetails.supraEntity">
                      {{ newADetails.supraEntity }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(SUPRANATIONAL_ENTITY) }}
                    </p>
                  </div>
                </div>
                <div class="accordian-data-row">
                  <div class="row-item">
                    <p class="input-label">
                      {{ SOVEREIGN_WEALTH_FUND }}
                    </p>
                    <p class="input-field" v-if="newADetails.sovWealthFund">
                      {{ newADetails.sovWealthFund }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(SOVEREIGN_WEALTH_FUND) }}
                    </p>
                  </div>
                  <div class="row-item">
                    <p class="input-label">
                      {{ COMMUNITY_DEVELOPMENT_FINANCIAL_INSTITUTION }}
                    </p>
                    <p class="input-field" v-if="newADetails.comDevFinInst">
                      {{ newADetails.comDevFinInst }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{
                        getPlaceholder(
                          COMMUNITY_DEVELOPMENT_FINANCIAL_INSTITUTION
                        )
                      }}
                    </p>
                  </div>
                  <div class="row-item">
                    <p class="input-label">
                      {{ MULTILATERAL_DEVELOPMENT_BANK }}
                    </p>

                    <p class="input-field" v-if="newADetails.multiDevBank">
                      {{ newADetails.multiDevBank }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(MULTILATERAL_DEVELOPMENT_BANK) }}
                    </p>
                  </div>
                </div>
                <div class="accordian-data-row">
                  <div class="row-item">
                    <p class="input-label">
                      {{ INDUSTRIAL_LOAN_BANK }}
                    </p>
                    <p class="input-field" v-if="newADetails.indLoanBank">
                      {{ newADetails.indLoanBank }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(INDUSTRIAL_LOAN_BANK) }}
                    </p>
                  </div>
                  <div class="row-item">
                    <p class="input-label">
                      {{ BALANCE_SHEET_CONSOLIDATION_FOR_SPECIAL_PURPOSE }}
                    </p>
                    <p class="input-field" v-if="newADetails.balSheetConsol">
                      {{ newADetails.balSheetConsol }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{
                        getPlaceholder(
                          BALANCE_SHEET_CONSOLIDATION_FOR_SPECIAL_PURPOSE
                        )
                      }}
                    </p>
                  </div>
                  <div class="row-item">
                    <p class="input-label">
                      {{ SWAP_DEALER }}
                    </p>
                    <p class="input-field" v-if="newADetails.swapDealer">
                      {{ newADetails.swapDealer }}
                    </p>
                    <p class="input-field-empty" v-else>
                      {{ getPlaceholder(SWAP_DEALER) }}
                    </p>
                  </div>
                </div>
              </div>
            </template>
            <template v-if="item.content === 'relationships'">
              <div class="accordian-data">
                <div class="table-container">
                  <Grid
                    ref="grid"
                    class="grid-data-table"
                    :style="{ width: '100%' }"
                    :data-items="relationshipData"
                    :resizable="true"
                    :sortable="true"
                    :sort="sort"
                    :filterable="false"
                    :take="take"
                    :skip="skip"
                    :total="total"
                    :columns="columns"
                    :column-menu="true"
                  >
                  </Grid>
                </div>
              </div>
            </template>
          </div>
        </div>
      </b-collapse>
    </section>
  </div>
</template>

<script>
import MenuStepper from '@/components/DMP/MenuStepper.vue'
import { mapActions, mapState, mapGetters } from 'vuex'
import { isEqual, isEmpty } from 'lodash'
import {
  entitiesStepperList,
  activeStepIndexName,
  basicDetailEvidenceId,
  indstryDetailEvdId,
  identifiersDetailsEvdIds,
  anaCreditEvdIds,
  details2052aEvidenceId,
  ENTERPRISE_SIZE_ENUM,
  ANA_C_IDENTIFIERS_NA,
  ANA_C_IDENTIFIERS_NOT_APP
} from '@/constant/data.js'
import { Grid } from '@progress/kendo-vue-grid'
import { Loader } from '@progress/kendo-vue-indicators'
import moment from 'moment'

// constants
const {
  ALIAS,
  LEGAL_ENTITY_NAME,
  PREVIOUS_ENTITY_NAME,
  LEGAL_STRUCTURE,
  BUSINESS_STATUS,
  ENTITY_TYPE,
  ENTITY_SUB_TYPE,
  US_GSE_FLAG,
  BRANCH_INDICATOR,
  BUSINESS_STATUS_INACTIVATION_REASON
} = basicDetailEvidenceId

const { CRN, INAI, TAX_ID, LEI, CIK, RSSD, CRD } = identifiersDetailsEvdIds

const {
  NAICS,
  SECONDARY_NAICS,
  NACE,
  SECONDARY_NACE,
  SIC,
  GERMAN_EXTENDED_NACE,
  NATURE_OF_BUSINESS
} = indstryDetailEvdId

const {
  NATIONAL_IDENTIFIERS,
  NATIONAL_IDENTIFIERS_TYPE,
  INST_SECTOR,
  INST_SUB_SECTOR,
  LEGAL_FORM,
  LEGAL_PROCEED_DATE,
  LEGAL_PROCEED_STATUS,
  ENTERPRISE_SIZE_DATE,
  NO_OF_EMPL,
  BALANCE_SHEET,
  ANNUAL_TURNOVER,
  ENTERPRISE_SIZE
} = anaCreditEvdIds

const {
  GSIB,
  GSIB_NAME,
  SUPRANATIONAL_ENTITY,
  SOVEREIGN_WEALTH_FUND,
  COMMUNITY_DEVELOPMENT_FINANCIAL_INSTITUTION,
  MULTILATERAL_DEVELOPMENT_BANK,
  INDUSTRIAL_LOAN_BANK,
  BALANCE_SHEET_CONSOLIDATION_FOR_SPECIAL_PURPOSE,
  SWAP_DEALER
} = details2052aEvidenceId

export default {
  name: 'EntityDetailsReview',
  components: { MenuStepper, Grid, Loader },

  props: {
    isExpandAll: {
      type: Boolean,
      default: false
    },
    viewSGAId: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      sgaId: '',
      loading: false,

      // constant
      // basic details
      ALIAS,
      LEGAL_ENTITY_NAME,
      PREVIOUS_ENTITY_NAME,
      LEGAL_STRUCTURE,
      BUSINESS_STATUS,
      ENTITY_TYPE,
      ENTITY_SUB_TYPE,
      US_GSE_FLAG,
      BRANCH_INDICATOR,
      BUSINESS_STATUS_INACTIVATION_REASON,
      SGA_ID: 'SGA ID',

      // Address Details
      ADDRESS_LINE_1: 'Address',
      FULL_ADDRESS: 'Full Address',
      DEPARTMENT: 'Department',
      SUB_DEPARTMENT: 'Sub Department',
      STREET_NAME: 'Street Name',
      BUILDING_NO: 'Building Number',
      BUILDING_NAME: 'Building Name',
      FLOOR: 'Floor',
      POSTBOX: 'Postbox',
      ROOM: 'Room',
      POSTCODE: 'Postcode',
      COUNTRY: 'Country',
      TOWN_NAME: 'Town Name',
      TOWN_LOC_NAME: 'Town Location Name',
      DISTRICT_NAME: 'District Name',
      COUNTRY_SUB_DIV: 'Country Sub Division',
      NUTS3_CODE: 'NUTS3 Code',
      COMPANY_NAME: 'C/O or Company Name',

      // industry details
      NAICS,
      SECONDARY_NAICS,
      SIC,
      NACE,
      SECONDARY_NACE,
      GERMAN_EXTENDED_NACE,
      NATURE_OF_BUSINESS,

      // Indentifiers
      CRN,
      INAI,
      TAX_ID_TYPE: 'Tax ID Type',
      TAX_ID,
      LEI,
      CIK,
      RSSD,
      CRD,

      // anacredit
      NATIONAL_IDENTIFIERS,
      NATIONAL_IDENTIFIERS_TYPE,
      INST_SECTOR,
      INST_SUB_SECTOR,
      LEGAL_FORM,
      LEGAL_PROCEED_DATE,
      LEGAL_PROCEED_STATUS,
      ENTERPRISE_SIZE_DATE,
      NO_OF_EMPL,
      BALANCE_SHEET,
      ANNUAL_TURNOVER,
      ENTERPRISE_SIZE,

      ENTERPRISE_SIZE_ENUM,
      ANA_C_IDENTIFIERS_NA,
      ANA_C_IDENTIFIERS_NOT_APP,
      highestIdentifierRank: 0,

      // 2052A
      GSIB,
      GSIB_NAME,
      SUPRANATIONAL_ENTITY,
      SOVEREIGN_WEALTH_FUND,
      COMMUNITY_DEVELOPMENT_FINANCIAL_INSTITUTION,
      MULTILATERAL_DEVELOPMENT_BANK,
      INDUSTRIAL_LOAN_BANK,
      BALANCE_SHEET_CONSOLIDATION_FOR_SPECIAL_PURPOSE,
      SWAP_DEALER,

      stepList: entitiesStepperList,
      activeStepIndexName: activeStepIndexName,
      activeStepIndex: -1,

      businessStatusOptions: [
        {
          value: true,
          text: 'Active'
        },
        {
          value: false,
          text: 'Inactive'
        }
      ],
      items: [
        {
          expanded: true,
          title: '1. Basic Details',
          content: 'basicDetails',
          isLoading: true
        },
        {
          expanded: false,
          title: '2. Address Details',
          content: 'addressDetails',
          isLoading: true
        },
        {
          expanded: false,
          title: '3. Industry Details',
          content: 'industryDetails',
          isLoading: true
        },
        {
          expanded: false,
          title: '4. Identifiers',
          content: 'identifiersDetails',
          isLoading: true
        },
        {
          expanded: false,
          title: '5. AnaCredit Details',
          content: 'anaCreditDetails',
          isLoading: true
        },
        {
          expanded: false,
          title: '6. 2052A Details',
          content: '2052ADetails',
          isLoading: true
        },
        {
          expanded: false,
          title: '7. Relationships',
          content: 'relationships',
          isLoading: true
        }
      ],
      columns: [
        {
          field: 'sgaId',
          title: 'SGA ID',
          width: '140px',
          columnMenu: false
        },
        {
          field: 'requestId',
          title: 'RequestID',
          width: '120px',
          columnMenu: false
        },
        {
          field: 'legalEntityName',
          title: 'Legal Entity Name',
          width: '180px',
          resizable: true,
          columnMenu: false,
          className: 'text-center'
        },
        {
          field: 'countryReg',
          title: 'Country Of Registration',
          resizable: true,
          sortable: true,
          width: 220,
          columnMenu: false
        },
        {
          field: 'countryDom',
          title: 'Country Of Domicile',
          resizable: true,
          sortable: true,
          width: 200,
          columnMenu: false
        },
        {
          field: 'immediateParent',
          title: 'Immediate Parent Name',
          sortable: true,
          width: '220px',
          columnMenu: false
        },
        {
          field: 'ultimateParent',
          title: 'Ultimate Parent Name',
          width: '200px',
          resizable: true,
          columnMenu: false
        },
        {
          field: 'relationship',
          title: 'Relationship Type',
          width: '180px',
          resizable: true,
          columnMenu: false,
          className: 'text-center',
          filter: 'boolean'
        },
        {
          field: 'ownership',
          title: 'Ownership %',
          width: '130px',
          resizable: true,
          columnMenu: false
        }
      ],
      expandField: 'expanded',
      gridData: [],
      skip: 0,
      take: 10,
      total: 0,
      sort: [],
      filter: null,
      customFilter: null,
      expandedItems: [],

      physicalCountrySubDiv: [],
      registeredCountrySubDiv: [],
      physicalNuts3Code: [],
      registeredNuts3Code: [],

      NO_LEGAL_ACTIONS_CODE: 'LG002',

      nationalIdentifierMinPair: 2,

      availabeEnterpriseSize: [
        'Small enterprise',
        'Medium enterprise',
        'Large enterprise',
        'Micro enterprise'
      ]
    }
  },

  computed: {
    ...mapState('basicDetails', [
      'entityTypeList',
      'entitySubTypeList',
      'basicDetails',
      'legaStructures',
      'inactivationReasons'
    ]),
    ...mapState('basicDetails', {
      basicEnrichmentAnsChoices: 'enrichmentAnswerChoices'
    }),

    ...mapState('dmp', [
      'registeredAddress',
      'physicalAddress',
      'businessAddress',
      'country',
      'countrySubDivision',
      'nuts3'
    ]),
    ...mapGetters('dmp', ['getEntityDetails']),

    ...mapState('identifiers', ['identifiersDetails']),

    ...mapState('industry', [
      'naicsList',
      'naceList',
      'secondaryNaceList',
      'sicList',
      'germanExtNaceList',
      'industryDetail'
    ]),

    ...mapState('anaCredit', [
      'nationalITypeOpts',
      'instSectorOpts',
      'instSubSectOpts',
      'legalFormOpts',
      'legalProceedStatusOpts',
      'enterpriseSizeOpts',
      'anaCreditDetails',
      'isAnaCreditApplicable'
    ]),

    ...mapState('details2052a', ['details2052a', 'enrichmentAnswerChoices']),
    ...mapState('details2052a', {
      aEnrichmentAnsChoices: 'enrichmentAnswerChoices'
    }),
    ...mapState('evidence', ['evidenceIdList']),
    ...mapState('relationship', ['relationshipData']),

    newBasicDetails() {
      const basicDetailsObj = {
        alias: null,
        legalEntityName: null, // mandatory
        previousEntityName: null,
        legalStructure: null,
        businessStatus: null,
        entityTypeValue: null, // mandatory
        entitySubTypeValue: null, // mandatory
        usGseFlag: null, // mandatory
        branchIndicator: null,
        businessStatusInactiveReason: null // mandatory
      }

      this.syncNewBasicDetailsWithBasicDetails(basicDetailsObj)
      return basicDetailsObj
    },

    newPhysicalAddress() {
      const physicalAddressObj = {
        addrLine1: null,
        fullAddress: null,
        department: null,
        subDepartment: null,
        streetName: null,
        buildingNumber: null,
        buildingName: null,
        floor: null,
        room: null,
        postBox: null,
        postCode: null, // mandatory
        town: null,
        townLocation: null,
        districtName: null,
        country: null, // mandatory
        nuts3: null,
        subDivision: null, // mandatory
        companyName: null
      }

      this.syncNewAddrWithAddr(
        physicalAddressObj,
        this.physicalAddress,
        this.physicalCountrySubDiv,
        this.physicalNuts3Code
      )
      return physicalAddressObj
    },

    newRegisteredAddress() {
      const registeredAddressObj = {
        addrLine1: null,
        fullAddress: null,
        department: null,
        subDepartment: null,
        streetName: null,
        buildingNumber: null,
        buildingName: null,
        floor: null,
        room: null,
        postBox: null,
        postCode: null,
        town: null,
        townLocation: null,
        districtName: null,
        country: null,
        nuts3: null,
        subDivision: null,
        companyName: null
      }

      this.syncNewAddrWithAddr(
        registeredAddressObj,
        this.registeredAddress,
        this.registeredCountrySubDiv,
        this.registeredNuts3Code
      )
      return registeredAddressObj
    },

    newIndustryDetails() {
      const industryDetailsObj = {
        naics: null, // mandatory
        secondaryNaics: null, // mandatory
        nace: null, // mandatory
        secondaryNace: null, // mandatory
        sic: null, // mandatory
        germanExtNace: null,
        businessNature: null
      }
      this.syncNewIndustryWithIndustry(industryDetailsObj)
      return industryDetailsObj
    },

    newIdentifiersDetails() {
      const indentifiersDetailsObj = {
        crn: null,
        inai: null, // mandatory
        taxIdType: null,
        taxId: null,
        lei: null,
        cik: null,
        rssd: null,
        crd: null,
        taxIdPairs: null
      }

      this.syncNewIdentifiersWithIdentifiers(indentifiersDetailsObj)
      return indentifiersDetailsObj
    },

    newAnaCreditDetails() {
      const anaCreditDetailsObj = {
        nationalIdentifierNo: null, // mandatory
        nationalIdentifierType: null, // mandatory
        institutionalSector: null, // mandatory
        legalForm: null, // mandatory
        legalProceedDate: null, // mandatory
        legalProceedStatus: null, // mandatory
        enterpriseSizeDate: null, // mandatory
        noOfEmployees: null, // mandatory
        balanceSheetTotal: null,
        annualTurnover: null,
        enterpriseSize: null, // mandatory
        isAnaCredit: null, // anacredit applicability
        identifierPairs: null
      }

      this.syncNewAnaCreditDetailsWithAnaCreditDetails(anaCreditDetailsObj)
      return anaCreditDetailsObj
    },

    newADetails() {
      const ADetailsObj = {
        gSib: null,
        gSibName: null, // mandatory
        supraEntity: null,
        sovWealthFund: null,
        comDevFinInst: null,
        multiDevBank: null,
        indLoanBank: null,
        balSheetConsol: null,
        swapDealer: null
      }

      this.syncNewADetailsWithADetails(ADetailsObj)
      return ADetailsObj
    }
  },

  watch: {
    isExpandAll() {
      if (this.isExpandAll) {
        this.activeStepIndex = -1
      }
    }
  },

  async mounted() {
    this.loading = true
    const { sgaId } = this.getEntityDetails
    this.sgaId = this.viewSGAId || sgaId

    // Get AnaCredit Applicability - Quick Refrence
    this.getAnaCreditApplicability(sgaId)

    // Apart from Basic Details all API calls handled Asynchronously
    // Basic Details
    if (isEmpty(this.basicDetails)) {
      await Promise.all([
        this.getEntityTypeList(),
        this.getBasicEnrichmentAnswerChoices(),
        this.getLegalStructures(),
        this.getBasicDetails(this.sgaId)
      ])
      // Basic Details Dependent Fields
      Promise.all([
        this.getEntitySubTypeList(this.basicDetails?.entityTypeId),
        this.getInactivationReason(
          this.newBasicDetails.businessStatus &&
            this.newBasicDetails.businessStatus === 'Active'
            ? 'ACTIVE'
            : 'INACTIVE'
        )
      ]).then(() => {
        this.setLoadingOfAccordion('basicDetails')
      })
    } else {
      this.setLoadingOfAccordion('basicDetails')
    }

    // Address Details: Country List
    if (isEmpty(this.physicalAddress) && isEmpty(this.registeredAddress)) {
      this.getAddressDetails(this.sgaId).then(() => {
        this.getCountryList()
        this.fetchAddressDependantField()
      })
    } else {
      this.fetchAddressDependantField()
    }

    // Industry Details
    if (isEmpty(this.industryDetail)) {
      this.getIndustryDetail(this.sgaId).then(() => {
        this.setLoadingOfAccordion('industryDetails')
      })
    } else {
      this.setLoadingOfAccordion('industryDetails')
    }

    // Indentifiers Details
    if (isEmpty(this.identifiersDetails)) {
      this.getIdentifiersDetails(this.sgaId).then(() => {
        this.setLoadingOfAccordion('identifiersDetails')
      })
    } else {
      this.setLoadingOfAccordion('identifiersDetails')
    }

    // AnaCredit & Dependent Fields
    if (isEmpty(this.anaCreditDetails) && this.isAnaCreditApplicable) {
      this.getAnaCreditDetails(this.sgaId).then(() => {
        Promise.all([
          this.getInstitutionalSectors(),
          this.getLegalForms(),
          this.getLegalProceedingsStatus(),
          this.getEnterpriseSize(),
          this.getNationalIdentifierTypes(
            this.anaCreditDetails?.registeredCountryId
          )
        ]).then(() => {
          this.setLoadingOfAccordion('anaCreditDetails')
        })
      })
    } else {
      this.setLoadingOfAccordion('anaCreditDetails')
    }

    // 2052A Details
    if (isEmpty(this.details2052a)) {
      Promise.all([
        this.getEnrichmentAnswerChoices(),
        this.get2052aDetails(this.sgaId)
      ]).then(() => {
        this.setLoadingOfAccordion('2052ADetails')
      })
    } else {
      this.setLoadingOfAccordion('2052ADetails')
    }

    // Relationship Details
    this.getRelationships({
      sgaId: this.sgaId
    }).then(() => {
      this.setLoadingOfAccordion('relationships')
    })
    this.loading = false
  },

  destroyed() {
    this.resetFormsDetails()
  },

  methods: {
    ...mapActions('basicDetails', [
      'getEntityTypeList',
      'getBasicDetails',
      'getBasicEnrichmentAnswerChoices',
      'getLegalStructures',
      'getEntitySubTypeList',
      'getInactivationReason'
    ]),

    ...mapActions('dmp', [
      'getAddressDetails',
      'getCountryList',
      'getStateList',
      'getNuts3List',
      'resetFormsDetails'
    ]),

    ...mapActions('industry', ['getIndustryDetail']),

    ...mapActions('identifiers', ['getIdentifiersDetails']),

    ...mapActions('anaCredit', [
      'getNationalIdentifierTypes',
      'getInstitutionalSectors',
      'getLegalForms',
      'getLegalProceedingsStatus',
      'getAnaCreditDetails',
      'getEnterpriseSize',
      'getAnaCreditApplicability'
    ]),

    ...mapActions('details2052a', [
      'get2052aDetails',
      'getEnrichmentAnswerChoices'
    ]),

    ...mapActions('relationship', ['getRelationships']),

    getPlaceholder(inputName) {
      // return `Select ${inputName}`
      return ''
    },

    getOpenState(index) {
      if (this.isExpandAll) {
        return true
      } else if (this.activeStepIndex > -1) {
        return this.activeStepIndex == index
      } else {
        return false
      }
    },

    handleCollapseChange(index) {
      // Update activeStepIndex when the collapse state changes
      this.activeStepIndex = index
    },

    closeCollapseChange(index) {
      // Update activeStepIndex when the collapse state changes
      this.activeStepIndex = -1
    },

    syncNewBasicDetailsWithBasicDetails(basicDetailsObj) {
      const {
        alias,
        legalEntityName,
        previousEntityName,
        legalStructure,
        businessStatus,
        usGseFlag,
        branchIndicator
      } = this.basicDetails || {}

      basicDetailsObj.alias = alias || null
      basicDetailsObj.previousEntityName = previousEntityName || null
      basicDetailsObj.legalEntityName = legalEntityName || null
      basicDetailsObj.branchIndicator = this.getValueFromList(
        branchIndicator,
        this.basicEnrichmentAnsChoices
      )
      basicDetailsObj.businessStatus = this.getValueFromList(
        businessStatus,
        this.businessStatusOptions
      )
      basicDetailsObj.businessStatusInactiveReason = this.getValueFromList(
        this.basicDetails.businessInactivationReasonId,
        this.inactivationReasons
      )
      basicDetailsObj.usGseFlag = this.getValueFromList(
        usGseFlag,
        this.basicEnrichmentAnsChoices
      )

      const localLegalStructure = legalStructure || null
      basicDetailsObj.legalStructure = this.getValueFromList(
        localLegalStructure,
        this.legaStructures
      )

      basicDetailsObj.entityTypeValue = this.getValueFromList(
        this.basicDetails.entityTypeId,
        this.entityTypeList
      )

      basicDetailsObj.entitySubTypeValue = this.getValueFromList(
        this.basicDetails.entitySubTypeId,
        this.entitySubTypeList
      )
    },

    syncNewAddrWithAddr(newAddressObj, addressObj, countrySubDiv, nuts3Code) {
      const {
        addrLine1,
        fullAddress,
        department,
        subDepartment,
        streetName,
        buildingNumber,
        buildingName,
        floor,
        room,
        postBox,
        postCode,
        town,
        townLocation,
        districtName,
        country,
        nuts3,
        subDivision,
        companyName
      } = addressObj || {}

      newAddressObj.addrLine1 = addrLine1
      newAddressObj.fullAddress = fullAddress
      newAddressObj.department = department
      newAddressObj.subDepartment = subDepartment
      newAddressObj.streetName = streetName
      newAddressObj.buildingNumber = buildingNumber
      newAddressObj.buildingName = buildingName
      newAddressObj.floor = floor
      newAddressObj.room = room
      newAddressObj.postBox = postBox
      newAddressObj.postCode = postCode
      newAddressObj.town = town
      newAddressObj.townLocation = townLocation
      newAddressObj.companyName = companyName
      newAddressObj.districtName = districtName
      newAddressObj.country = this.getValueFromList(
        country?.value || null,
        this.country
      )
      newAddressObj.nuts3 = this.getValueFromList(
        nuts3?.value || null,
        nuts3Code
      )
      newAddressObj.subDivision = this.getValueFromList(
        subDivision?.value || null,
        countrySubDiv
      )
    },

    syncNewIndustryWithIndustry(industryDetailsObj) {
      const {
        naics,
        secondaryNaics,
        nace,
        secondaryNace,
        sic,
        germanExtNace,
        businessNature
      } = this.industryDetail || {}

      industryDetailsObj.naics = this.getValueFromList(
        naics || null,
        this.naicsList
      )
      industryDetailsObj.nace = this.getValueFromList(
        nace || null,
        this.naceList
      )
      industryDetailsObj.sic = this.getValueFromList(sic || null, this.sicList)
      industryDetailsObj.secondaryNaics = this.getValueFromList(
        secondaryNaics || null,
        this.naicsList
      )
      industryDetailsObj.secondaryNace = this.getValueFromList(
        secondaryNace || null,
        this.secondaryNaceList
      )
      industryDetailsObj.germanExtNace = this.getValueFromList(
        germanExtNace || null,
        this.germanExtNaceList
      )

      industryDetailsObj.businessNature = businessNature
    },

    syncNewIdentifiersWithIdentifiers(indentifiersDetailsObj) {
      const { crn, inai, taxIdType, taxId, lei, cik, rssd, crd, taxIdPairs } =
        this.identifiersDetails || {}

      indentifiersDetailsObj.crn = crn
      indentifiersDetailsObj.inai = inai
      indentifiersDetailsObj.taxIdType = taxIdType
      indentifiersDetailsObj.taxId = taxId
      indentifiersDetailsObj.lei = lei
      indentifiersDetailsObj.cik = cik
      indentifiersDetailsObj.rssd = rssd
      indentifiersDetailsObj.crd = crd
      indentifiersDetailsObj.taxIdPairs = taxIdPairs || [null]
    },

    syncNewAnaCreditDetailsWithAnaCreditDetails(anaCreditDetailsObj) {
      const {
        nationalIdentifierNo,
        nationalIdentifierType,
        institutionalSector,
        legalForm,
        legalProceedDate,
        legalProceedStatus,
        enterpriseSizeDate,
        noOfEmployees,
        balanceSheetTotal,
        annualTurnover,
        enterpriseSize,
        isAnaCredit,
        identifierPairs
      } = this.anaCreditDetails || {}

      anaCreditDetailsObj.isAnaCredit = isAnaCredit || null
      anaCreditDetailsObj.nationalIdentifierNo = nationalIdentifierNo || null
      anaCreditDetailsObj.nationalIdentifierType = this.getValueFromList(
        nationalIdentifierType,
        this.nationalITypeOpts
      )
      anaCreditDetailsObj.institutionalSector = this.getValueFromList(
        institutionalSector,
        this.instSectorOpts
      )
      anaCreditDetailsObj.legalForm = this.getValueFromList(
        legalForm,
        this.legalFormOpts
      )
      anaCreditDetailsObj.legalProceedDate = legalProceedDate
        ? moment(legalProceedDate).format('MM/DD/YYYY')
        : null
      anaCreditDetailsObj.legalProceedStatus = this.getValueFromList(
        legalProceedStatus,
        this.legalProceedStatusOpts
      )
      anaCreditDetailsObj.enterpriseSizeDate = enterpriseSizeDate
        ? moment(enterpriseSizeDate).format('MM/DD/YYYY')
        : null
      anaCreditDetailsObj.noOfEmployees = noOfEmployees || null
      anaCreditDetailsObj.balanceSheetTotal = balanceSheetTotal || null
      anaCreditDetailsObj.annualTurnover = annualTurnover || null
      anaCreditDetailsObj.enterpriseSize =
        this.enterpriseSizeOpts?.find((opt) => opt.value === enterpriseSize)
          ?.text || null

      // Highest Rank for Identifiers
      const niOptsWONA = this.nationalITypeOpts.filter(
        (opt) =>
          opt.rank !== this.ANA_C_IDENTIFIERS_NA.RANK &&
          opt.rank !== this.ANA_C_IDENTIFIERS_NOT_APP.RANK
      )
      this.highestIdentifierRank =
        niOptsWONA && niOptsWONA.length > 0
          ? Math.max(...niOptsWONA.map((opt) => opt.rank))
          : 0

      // Set min identifiers pairs
      anaCreditDetailsObj.identifierPairs =
        this.setNationalIdentifierPair(identifierPairs)
    },

    syncNewADetailsWithADetails(ADetailsObj) {
      const {
        gSib,
        gSibName,
        supraEntity,
        sovWealthFund,
        comDevFinInst,
        multiDevBank,
        indLoanBank,
        balSheetConsol,
        swapDealer
      } = this.details2052a || {}

      const gsibList = this.enrichmentAnswerChoices // Not filtering the true value, as whatever is the db value, it needs to be displayed.
      ADetailsObj.gSib = this.getValueFromList(gSib || null, gsibList)
      ADetailsObj.gSibName = gSibName
      ADetailsObj.supraEntity = this.getValueFromList(
        supraEntity || null,
        this.aEnrichmentAnsChoices
      )
      ADetailsObj.sovWealthFund = this.getValueFromList(
        sovWealthFund || null,
        this.aEnrichmentAnsChoices
      )
      ADetailsObj.comDevFinInst = this.getValueFromList(
        comDevFinInst || null,
        this.aEnrichmentAnsChoices
      )
      ADetailsObj.multiDevBank = this.getValueFromList(
        multiDevBank || null,
        this.aEnrichmentAnsChoices
      )
      ADetailsObj.indLoanBank = this.getValueFromList(
        indLoanBank || null,
        this.aEnrichmentAnsChoices
      )
      ADetailsObj.balSheetConsol = this.getValueFromList(
        balSheetConsol || null,
        this.aEnrichmentAnsChoices
      )
      ADetailsObj.swapDealer = this.getValueFromList(
        swapDealer || null,
        this.aEnrichmentAnsChoices
      )
    },

    getValueFromList(obj, list) {
      return (
        list.find((item) => {
          return item.value === obj || isEqual(item, obj)
        })?.text ?? obj?.text
      )
    },

    mandatoryValidation() {
      const objList = [
        this.newBasicDetails,
        this.newPhysicalAddress,
        this.newRegisteredAddress,
        this.newIndustryDetails,
        this.newIdentifiersDetails,
        this.newAnaCreditDetails,
        this.newADetails
      ]

      const commonBDMandatoryFields = [
        'legalEntityName',
        'entityTypeValue',
        'entitySubTypeValue',
        'usGseFlag'
      ]
      const basicDetailMandatoryFields =
        this.newBasicDetails.businessStatus === 'Inactive'
          ? [...commonBDMandatoryFields, 'businessStatusInactiveReason']
          : [...commonBDMandatoryFields]

      const addrDetailMandatoryFields = ['postCode', 'country', 'subDivision']

      const commonIDMandatoryFields = ['naics', 'nace', 'sic', 'businessNature']
      const industryDetailMandatoryFields = this.newIndustryDetails
        ?.secondaryNaics
        ? [...commonIDMandatoryFields, 'secondaryNace']
        : [...commonIDMandatoryFields]

      const indentifiersDetailMandatoryFields = this.newIdentifiersDetails.crn
        ? ['inai']
        : []

      const commonACDMandatoryFields = [
        'identifierPairs',
        'institutionalSector',
        'legalForm',
        'legalProceedDate',
        'legalProceedStatus',
        'enterpriseSizeDate'
      ]

      let commonACDMandatoryFieldsFiltered =
        this.newAnaCreditDetails?.legalProceedStatus?.split('-')[0].trim() ===
        this.NO_LEGAL_ACTIONS_CODE
          ? commonACDMandatoryFields.filter(
              (field) => field !== 'legalProceedDate'
            )
          : commonACDMandatoryFields

      // Identifier Pair Mandate Check
      commonACDMandatoryFieldsFiltered =
        this.highestIdentifierRank === 0
          ? commonACDMandatoryFieldsFiltered.filter(
              (field) => field !== 'identifierPairs'
            )
          : commonACDMandatoryFieldsFiltered

      commonACDMandatoryFieldsFiltered = !this.availabeEnterpriseSize.includes(
        this.newAnaCreditDetails.enterpriseSize
      )
        ? commonACDMandatoryFieldsFiltered.filter(
            (field) => field !== 'enterpriseSizeDate'
          )
        : commonACDMandatoryFieldsFiltered
      const anaCreditDetailMandatoryFields = this.isAnaCreditApplicable
        ? [...commonACDMandatoryFieldsFiltered]
        : []

      const ADetailMandatoryFields = this.newADetails.gSib ? ['gSibName'] : []

      const relationshipDetailMandatoryFields = []

      const errorFields = {}
      let isMandatoryPassed = true
      const idToFieldName = {
        alias: this.ALIAS,
        legalEntityName: this.LEGAL_ENTITY_NAME, // mandatory
        previousEntityName: this.PREVIOUS_ENTITY_NAME,
        legalStructure: this.LEGAL_STRUCTURE,
        businessStatus: this.BUSINESS_STATUS,
        entityTypeValue: this.ENTITY_TYPE, // mandatory
        entitySubTypeValue: this.ENTITY_SUB_TYPE, // mandatory
        usGseFlag: this.US_GSE_FLAG, // mandatory
        branchIndicator: this.BRANCH_INDICATOR,
        businessStatusInactiveReason: this.BUSINESS_STATUS_INACTIVATION_REASON, // mandatory

        addrLine1: this.ADDRESS_LINE_1,
        fullAddress: this.FULL_ADDRESS,
        department: this.DEPARTMENT,
        subDepartment: this.SUB_DEPARTMENT,
        streetName: this.STREET_NAME,
        buildingNumber: this.BUILDING_NO,
        buildingName: this.BUILDING_NAME,
        floor: this.FLOOR,
        room: this.ROOM,
        postBox: this.POSTBOX,
        postCode: this.POSTCODE, // mandatory
        town: this.TOWN_NAME,
        townLocation: this.TOWN_LOC_NAME,
        districtName: this.DISTRICT_NAME,
        country: this.COUNTRY, // mandatory
        nuts3: this.NUTS3_CODE,
        subDivision: this.COUNTRY_SUB_DIV, // mandatory
        companyName: this.COMPANY_NAME,

        naics: this.NAICS,
        nace: this.NACE,
        sic: this.SIC,
        germanExtNace: this.GERMAN_EXTENDED_NACE,
        secondaryNaics: this.SECONDARY_NAICS,
        secondaryNace: this.SECONDARY_NACE,
        businessNature: this.NATURE_OF_BUSINESS,

        crn: this.CRN,
        inai: this.INAI, // mandatory
        taxIdType: this.TAX_ID_TYPE,
        taxId: this.TAX_ID,
        lei: this.LEI,
        cik: this.CIK,
        rssd: this.RSSD,
        crd: this.CRD,

        nationalIdentifierNo: this.NATIONAL_IDENTIFIERS, // mandatory
        nationalIdentifierType: this.NATIONAL_IDENTIFIERS_TYPE, // mandatory
        institutionalSector: this.INST_SECTOR, // mandatory
        legalForm: this.LEGAL_FORM, // mandatory
        legalProceedDate: this.LEGAL_PROCEED_DATE, // mandatory
        legalProceedStatus: this.LEGAL_PROCEED_STATUS, // mandatory
        enterpriseSizeDate: this.ENTERPRISE_SIZE_DATE, // mandatory
        noOfEmployees: this.NO_OF_EMPL, // mandatory
        balanceSheetTotal: this.BALANCE_SHEET,
        annualTurnover: this.ANNUAL_TURNOVER,
        enterpriseSize: this.ENTERPRISE_SIZE, // mandatory

        gSib: this.GSIB,
        gSibName: this.GSIB_NAME, // mandatory
        supraEntity: this.SUPRANATIONAL_ENTITY,
        sovWealthFund: this.SOVEREIGN_WEALTH_FUND,
        comDevFinInst: this.COMMUNITY_DEVELOPMENT_FINANCIAL_INSTITUTION,
        multiDevBank: this.MULTILATERAL_DEVELOPMENT_BANK,
        indLoanBank: this.INDUSTRIAL_LOAN_BANK,
        balSheetConsol: this.BALANCE_SHEET,
        swapDealer: this.SWAP_DEALER
      }

      for (const obj of objList) {
        const fields = []
        for (const property in obj) {
          if (basicDetailMandatoryFields.includes(property)) {
            if (isEmpty(obj[property])) {
              isMandatoryPassed = false
              fields.push(idToFieldName[property])
            }
            if (fields.length > 0) {
              errorFields['Basic Details'] = fields
            }
          }
          if (addrDetailMandatoryFields.includes(property)) {
            if (isEmpty(obj[property])) {
              isMandatoryPassed = false
              fields.push(idToFieldName[property])
            }
            if (fields.length > 0) {
              errorFields['Address Details'] = fields
            }
          }
          if (industryDetailMandatoryFields.includes(property)) {
            if (isEmpty(obj[property])) {
              isMandatoryPassed = false
              fields.push(idToFieldName[property])
            }
            if (fields.length > 0) {
              errorFields['Industry Details'] = fields
            }
          }
          if (indentifiersDetailMandatoryFields.includes(property)) {
            if (isEmpty(obj[property])) {
              isMandatoryPassed = false
              fields.push(idToFieldName[property])
            }
            if (fields.length > 0) {
              errorFields.Identifiers = fields
            }
          }
          if (anaCreditDetailMandatoryFields.includes(property)) {
            // Identifier Pair Validation Check
            if (property === 'identifierPairs') {
              const isValidPairs =
                (this.highestIdentifierRank >= this.nationalIdentifierMinPair
                  ? obj[property].length >= this.nationalIdentifierMinPair
                  : obj[property].length >= 1) &&
                this.isValidNationalIdentifierPair(obj[property])
              if (!isValidPairs) {
                isMandatoryPassed = false
                fields.push(
                  this.NATIONAL_IDENTIFIERS,
                  this.NATIONAL_IDENTIFIERS_TYPE
                )
              }
            } else if (isEmpty(obj[property])) {
              isMandatoryPassed = false
              fields.push(idToFieldName[property])
            }
            if (fields.length > 0) {
              errorFields['AnaCredit Details'] = fields
            }
          }
          if (ADetailMandatoryFields.includes(property)) {
            if (isEmpty(obj[property])) {
              isMandatoryPassed = false
              fields.push(idToFieldName[property])
            }
            if (fields.length > 0) {
              errorFields['2052A Details'] = fields
            }
          }
          if (relationshipDetailMandatoryFields.includes(property)) {
            if (isEmpty(obj[property])) {
              isMandatoryPassed = false
              fields.push(idToFieldName[property])
            }
            if (fields.length > 0) {
              errorFields['Relationship Details'] = fields
            }
          }
        }
      }

      const mandatoryVal = {
        isMandatoryPassed,
        errorFields
      }

      return mandatoryVal
    },
    isValidNationalIdentifierPair(identifierPair) {
      let isValidPair = false
      if (identifierPair && identifierPair?.length !== 0) {
        identifierPair.every((pair) => {
          isValidPair =
            pair && ![pair?.type, pair?.value].every((item) => isEmpty(item))
          return isValidPair
        })
      }
      return isValidPair
    },
    setNationalIdentifierPair(identifierPairs) {
      identifierPairs = identifierPairs || []
      if (identifierPairs.length > 1) {
        return identifierPairs
      } else if (this.highestIdentifierRank > 1) {
        return identifierPairs.concat(
          Array(Math.max(0, 2 - identifierPairs.length)).fill(null)
        )
      } else {
        return identifierPairs.length === 0 ? [null] : [...identifierPairs]
      }
    },
    setLoadingOfAccordion(accordionName, loadingStatus = false) {
      this.items.forEach((item, index) => {
        if (item.content === accordionName) {
          this.items[index].isLoading = loadingStatus
        }
      })
    },
    fetchAddressDependantField() {
      // Address Details Dependent Field: State & Nuts3
      Promise.all([
        this.getStateList(this.physicalAddress?.country.value),
        this.getNuts3List(this.physicalAddress?.country.value)
      ]).then(() => {
        this.physicalCountrySubDiv = this.countrySubDivision
        this.physicalNuts3Code = this.nuts3
        // Registered Address
        // Nested API call because using same store state
        if (!isEmpty(this.registeredAddress)) {
          Promise.all([
            this.getStateList(this.registeredAddress?.country.value),
            this.getNuts3List(this.registeredAddress?.country.value)
          ]).then(() => {
            this.registeredCountrySubDiv = this.countrySubDivision
            this.registeredNuts3Code = this.nuts3
            this.setLoadingOfAccordion('addressDetails')
          })
        } else {
          this.setLoadingOfAccordion('addressDetails')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.card-header-title {
  font-size: 18px;
  color: var(--inava-primary-dark);
}

.entity-details-review {
  display: flex;
  width: 100%;
  height: auto;
  position: relative;

  .menu-stepper-container {
    flex: 0 0 281px;
    padding: 12px 0px 12px 12px;
    height: auto;
    width: 25%;
  }

  .table-container {
    width: 100%;
  }

  .view-evidence-button {
    background-color: #c4c8f8;
    color: blue;
    padding: 5px 10px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    font-size: 10px;
  }

  .action-icon-blue {
    color: blue;
  }

  .action-icon-red {
    color: red;
  }

  .icon-space {
    display: inline-block;
    width: 10px;
  }

  .form-review-container {
    width: 75%;
    margin: 10px;
  }

  ::v-deep .card {
    border-radius: unset;
    -webkit-box-shadow: unset;
    box-shadow: unset;
    margin-bottom: 25px;

    .card-header {
      -webkit-box-shadow: unset;
      box-shadow: unset;
      border-bottom: 1px solid #ccc;
    }

    .card-footer:last-child,
    .card-content:last-child,
    .card-header:last-child {
      border-bottom-left-radius: unset;
      border-bottom-right-radius: unset;
    }
  }
  .accordian-data {
    .non-anacredit-msg {
      text-align: left;
      font: normal normal 13px/16px Quicksand;
      font-weight: 600;
      letter-spacing: 0.18px;
      font-size: large;
      color: #f57777;
    }
    .accodian-data-section-title {
      color: #535eeb;
      font-size: 20px;
    }

    .input-label {
      color: #7d7d7d;
      font-size: 12px;

      .mandatory {
        color: red;
      }
    }

    .input-field {
      color: #000000;
      font-size: 14px;
      font-weight: 600;
    }

    .input-field-empty {
      color: #cccccc;
      font-size: 14px;
      font-weight: 600;
    }

    .solid-seperator {
      border-top: 1px solid #535eeb;
    }
  }

  .skeleton-loading {
    background-color: #f0f0f0;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
  }

  .skeleton-loading::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.4),
      transparent
    );
    animation: loading 1.5s infinite;
  }

  @keyframes loading {
    0% {
      left: -100%;
    }
    100% {
      left: 100%;
    }
  }

  .accordian-data-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    gap: 12px;

    .row-item {
      flex-grow: 1;
      flex-basis: 0;
      display: flex;
      flex-direction: column;

      .input-label {
        font-size: 16px;
        font-weight: medium;
        letter-spacing: 0.16px;
        margin-bottom: 5px;
      }

      .input-field {
        font-size: 20px;
        font-weight: 600;
        margin: 0;
      }
    }

    .row-item-empty {
      flex-grow: 0;
    }
  }

  ::v-deep .k-table-thead {
    background: #dfdfdf 0% 0% no-repeat padding-box;
  }

  hr {
    background-color: #535eeb;
    border: none;
    display: block;
    height: 1px;
    margin: 0.5rem 0rem;
  }

  .flex-container {
    display: flex;
    justify-content: space-between;
  }

  .ana-credit-subtitle {
    text-align: left;
    font-size: 15px;
    font-family: Quicksand;
    font-weight: 600;
    line-height: 15px;
    font-weight: 500;
    letter-spacing: 0.2px;
    color: #535eeb;
    opacity: 1;
  }
}
</style>
<style lang="scss">
.entity-details-review {
  .menu-stepper-container {
    .menu-stepper {
      .stepper-list {
        .step-item {
          &::before {
            color: #888888;
            background-color: transparent;
          }

          &:hover {
            background-color: rgba(83, 94, 235, 0.2);

            &::before {
              background-color: transparent;
              color: #00218a;
              font-size: 15px;
            }
          }

          a {
            color: #7d7d7d;

            &:hover {
              color: #00218a;
              font-size: 15px;
            }
          }
        }

        .step-active {
          background-color: rgba(83, 94, 235, 0.2);

          a {
            color: #00218a;
            font-size: 15px;
          }

          &::before {
            font-size: 15px;
            background-color: transparent;
            color: #00218a;
          }
        }
      }
    }
  }

  .local-loader-container {
    position: fixed;
  }
}
</style>
